/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "../node_modules/ngx-toastr/toastr.css";
@import "remixicon/fonts/remixicon.css";

* {
  //font-family: "Poppins", sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
}

// // css hack to prevent UI from  distorting in low resolutions systems
// @media only screen and (min-width: 1000px) and (max-width: 1300px) {
//   body {
//     zoom: 80%;
//   }
// }

.searchMenu {
  box-shadow: none;
}




body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
p,
.mat-table {
  font-family: "Roboto", sans-serif;
}

a,
button {
  cursor: pointer;
}

.mat-error {
  font-size: 11px !important;
}

.md-drppicker .calendar td,
.md-drppicker .calendar th {
  font-size: 12px !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background: #f7f7f7 !important;
}

mat-sidenav-content {
  background: #f7f7f7;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.mat-icon {
  cursor: pointer;
}

.groupdViewToggle {
  .mat-slide-toggle-bar {
    background: none;
    border: 1px solid #cbd7dc;
  }

  .mat-slide-toggle-bar {
    width: 30px;
    height: 16px;
  }

  .mat-slide-toggle-thumb-container {
    left: 2px !important;
    top: 2px !important;
  }

  .mat-slide-toggle-thumb {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background: #cbd7dc;
  }
}

.mat-sort-header-arrow {
  color: black !important;
}

.mat-sort-header-stem {
  height: 6px !important;
}

.dash-sidenav-list {
  &.mat-list-base {
    height: 52px;
  }

  .mat-list-item-content {
    display: flex;
    flex-direction: row-reverse !important;
    // margin: 8px;
  }

  .mat-list-item {
    height: 38px !important;

    .mat-line {
      font-size: 14px !important;
      visibility: hidden;
    }

    img {
      margin: 8px;
    }

    .mat-list-item-content {
      padding: 8px !important;
      justify-content: center;
    }
  }
}

.mat-expansion-panel-body {
  .mat-checkbox-background {
    background: #dbdbdb !important;
    opacity: 1;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  .mat-checkbox {
    line-height: 2.3;
    display: block;
  }
}

.mat-table {
  tr.mat-header-row {
    height: 40px !important;
  }

  th.mat-header-cell {
    color: black;
    font-size: 14px !important;
    font-weight: 500;
  }

  td.mat-cell {
    font-size: .8rem;
  }
}

.mat-flat-button[disabled][disabled] {
  background: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.custom-modalbox {
  position: fixed !important;
  right: 0px;
  top: 0;

  mat-dialog-container {
    padding: 0;
  }
}

.mat-dialog-container {
  padding: 0px !important;
}

//auth module css
.signup_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f5f9fc;

  .wrap {
    width: 60%;
    height: 60%;
    margin: auto;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #ccc !important;
    border-radius: 6px;
    position: relative;
    box-shadow: 0px 0px 14px #00000029;

    // height: 69vh;
    //top: 10%;
    .left_panel {
      width: 58vh;
      height: 76vh;
      padding: 4vh;
      margin: auto;
      border-radius: 6px;

      // background-color: #FFFFFF;
      form {
        margin-bottom: 15px;

        .signup_form {
          margin-bottom: 20px;
        }

        .btn_login {
          background: #3640bb 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
          color: white;
        }
      }

      .signup_form .mat-form-field.p-email {
        width: 91% !important;
      }

      .signup_form .mat-form-field {
        margin-right: 20px;
      }

      h5 {
        font-size: 3vh;
        margin-bottom: 14px;
        font-weight: normal;
        opacity: 1;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .right_panel {
      width: 41%;
      //margin-right: 10px;
      position: relative;

      .background_cover {
        width: 100%;

        img {
          height: 100%;
          width: 100%;
        }

        h5 {
          margin-top: 0px;
          font-size: 29px;
          color: white;
          width: 68%;
          padding: 50% 0px 0px 5%;
          padding: 48% 0px 0px 5%;
          margin-bottom: 17px;
        }

        .back_img_cover {
          display: flex;
          padding-left: 5%;
          flex-wrap: wrap;

          .img_post {
            display: flex;
            width: 30%;

            img {
              height: 14px;
              margin-top: 3px;
            }

            h4 {
              margin-top: 0px;
              color: white;
              margin-left: 8px;
              font-size: 13px;
              font-size: 14px;
              margin-bottom: 10px;
            }
          }

          .payment {
            width: 75% !important;
          }
        }
      }
    }
  }

  .p-sign-in {
    width: 69%;
    margin: auto;
    margin-top: 70px;

    h5 {
      font-weight: normal;
      font-size: 13px;

      a {
        color: #3640bb;
        text-decoration: underline;
      }
    }
  }
}

.mat-form-field.mat-form-field .mat-form-field-label mat-label {
  font-size: 13px !important;
  color: #8d8d8d !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  //change checkbox back color
  background: #022b5c !important;
}

.signup_form .mat-form-field-wrapper,
.acc-form .mat-form-field-wrapper {
  padding-bottom: 1em !important;
}

//pages-init sidenav bar
.sidenva_menuList {
  .mat-list-base .mat-list-item .mat-list-item-content {
    color: white !important;
    font-size: 14px;
    padding: 0px;
  }
}

mat-form-field.dash-head-selct {
  margin-left: 20px;
  // width: 18% !important;
  top: 0px;
  left: 18px;

  .mat-form-field-flex {
    padding: 0px !important;
    background: none;
  }

  .mat-form-field-underline::before {
    height: 0px !important;
  }

  .mat-form-field-infix {
    border-top: 0px !important;
  }

  .mat-select-arrow-wrapper {
    vertical-align: bottom;
  }

  .mat-select-value {
    max-width: 150px;
    width: auto;
  }
}

::ng-deep .dash-head-selct:hover .mat-form-field-underline {
  display: none;
  appearance: none;
  outline: 0;
}

.commonfield .mat-form-field-underline {
  height: 0px !important;
}

.commonfield.mat-form-field .mat-form-field-infix {
  border-top: none;
  padding-top: 0px;
}

.commonfield.mat-form-field {
  border: 1px solid #ccc;
  background: white;
  height: 35px;
  border-radius: 4px;
}

.commonfield.mat-form-field-appearance-legacy .mat-form-field-label {
  font-size: 12px;
  padding: 8px;
}

.mat-option-text {
  font-size: 13px;
}

.mat-select-value-text {
  font-size: 13px;
}

.mataccWrap {
  .mat-expansion-panel-spacing {
    margin: 10px 0;
  }

  .mat-expansion-panel-header {
    padding: 0 15px;
    position: relative;

    .mat-expansion-indicator {
      position: absolute;
      top: 6px;
      right: 12px;
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        top: 10px;
      }
    }
  }

  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 0 15px 15px;
    }
  }

  .mat-radio-button {
    margin-bottom: 5px;
    display: block;

    .mat-radio-label {
      font-size: 13px;
    }
  }

  .mat-checkbox-layout .mat-checkbox-label {
    font-size: 13px;
  }
}

.dash-head-selct {
  .mat-form-field-wrapper {
    position: relative;
    height: 40px;
    padding-bottom: 0;
  }

  &.mat-form-field-appearance-fill {
    .mat-select-arrow-wrapper {
      transform: translateY(-10%);
      display: table-cell;

      .mat-select-arrow {
        color: #fff;
        border-left: 8px solid transparent !important;
        border-right: none !important;
        border-top: 8px solid transparent !important;
      }
    }

    .mat-form-field-ripple {
      height: 0;
    }
  }
}

// .mat-select-panel-wrap {
//   flex-basis: 1% !important;
// }
.ng-tns-c4-1 {
  &.mat-select-panel {
    max-width: 280px;

    .mat-option {
      line-height: 16px;
      padding: 7px 15px;
      height: auto;
      font-size: 13px;

      strong {
        font-weight: 600;
      }
    }
  }
}

.ng-tns-c6-2 {
  &.mat-menu-panel {
    .mat-menu-content {
      .mat-menu-item {
        line-height: 33px;
        font-size: 13px;
        height: 33px;
      }
    }
  }
}

.mat-checkbox-background {
  background: #dbdbdb !important;
  opacity: 1;
  border: 1px solid #ccc;
  border-radius: 2px;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-left: 10px !important;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0 5px !important;
}

td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 15px !important;
}

.table_invoice {
  thead {
    tr {
      th {
        &.mat-header-cell {
          font-weight: 500;
          font-size: 11px;
          padding: 0 5px;
        }

        .chk_type {
          padding-left: 7px;
        }
      }
    }
  }

  tbody {
    tr {
      height: 39px;

      &:hover,
      &.selectedrow {
        background: #f2f7ff;
      }

      td {
        &.mat-cell {
          padding: 0 5px;
          font-size: 12px;
          color: #393939;
        }

        &.mat-column-check {
          img {
            width: 27px;
            vertical-align: top;
            display: block;
          }

          .file {
            i {
              font-size: 19px;
              color: #cbd7dc;
            }
          }
        }

        &.mat-column-customer_account_name {
          max-width: 160px;
          width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.mat-column-plantName {
          &>span {
            max-width: 130px;
            width: 130px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.mat-column-invoiceDate {
          max-width: 125px;
          width: 125px;
        }

        &.mat-column-supplierName,
        &.mat-column-credit_terms {
          width: 118px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 118px;
        }

        &.mat-column-customerName {
          width: 118px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 118px;
        }

        &.mat-column-receivable {
          max-width: 95px;
          width: 95px;
        }

        &.mat-column-categoryFlag {
          max-width: 80px;
          width: 80px;
        }

        &.mat-column-amount {
          max-width: 115px;
          width: 115px;
        }

        &.mat-column-receivableEarly {
          max-width: 125px;
          width: 125px;
        }

        &.mat-column-payable {
          max-width: 125px;
          width: 125px;
        }

        &.mat-column-plantID {
          max-width: 95px;
          width: 95px;
        }

        &.mat-column-warehouse {
          max-width: 125px;
          width: 125px;
        }

        &.mat-column-biz_type {
          max-width: 100px;
          width: 100px;
        }

        &.mat-column-supp_payment_terms {
          // max-width: 110px;
          width: 115px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 15px !important;
        }

        &.mat-column-last_modified {
          width: 110px;
        }

        &.mat-column-categoryFlag {
          max-width: 80px;
          width: 80px;
        }

        &.mat-column-eligibleInvoice {
          width: 105px;
          word-break: break-word;
        }

        &.mat-column-requestValue {
          width: 106px;
          word-break: break-word;
        }

        &.mat-column-delayStatus span {
          background: #fea829 !important;
          color: white !important;
          text-align: center;
          vertical-align: top;
          border-radius: 14px;
          display: inline-block;
          line-height: 1.7;
          max-width: 90;
          font-size: 10px;
          width: auto;
          overflow: hidden;
          white-space: nowrap;
          width: 56px;
          text-overflow: ellipsis;
        }

        &.mat-column-invoiceNumber,
        &.mat-column-searchKey {
          .overtext {
            width: 130px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #0787dd;
            cursor: pointer;
          }
        }

        .status {
          width: auto;
          background: #ccc;
          text-align: center;
          vertical-align: top;
          border-radius: 14px;
          cursor: pointer;
          display: inline-block;
          padding: 4px 10px;
          max-width: 90;
          font-size: 10px;
          width: auto;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          i {
            margin-right: 3px;
            vertical-align: middle;
            display: inline-block;
          }

          span {
            vertical-align: top;
            display: inline-block;
            font-size: 10px;
            font-weight: 500;
          }

          &.open {
            background: #c7e8ff;
            color: #0787dd;

            i,
            span {
              color: #0787dd;
            }
          }

          &.early_status1 {
            background: #d9d9d9;
          }

          &.early_status2 {
            background: #ffd6fd;
            color: #c51cbe;
          }

          &.early_status3 {
            background: #e1f5d6;
            color: #287f07;
          }
        }
      }
    }
  }
}

.groupdViewToggle.mat-slide-toggle {
  .mat-slide-toggle-ripple {
    top: -14px;
    left: -7px;
  }
}

.mat-button {
  font-size: 13px;
}

.ngx-mat-drp-date-display {
  min-width: 0 !important;
  width: 100%;

  .mat-form-field-flex {
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .mat-form-field-label {
    font-size: 12px;
    display: inline-block;
    width: auto;
    background: #fff;
    padding: 0 2px;
    left: 7px;

    &.mat-empty {
      top: 13px;
    }
  }

  &.mat-form-field-hide-placeholder {
    .mat-form-field-label {
      top: 14px;
      display: inline-block !important;
      width: auto !important;
    }
  }

  .ngx-mat-drp-date-input {
    font-size: 10px;
    padding-left: 10px;
    height: 40px;
  }

  &.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0;
  }

  .mat-form-field-infix {
    padding: 0;
    border-top: 0;
  }

  .mat-form-field-label-wrapper {
    top: -4px;
    overflow: visible;
  }
}

// .restpass_wrap{
// .formarea{
//   .mat-form-field-wrapper{padding-bottom: 0px;}
// }
// }

.mat-menu-item {
  height: 36px !important;
  line-height: 38px !important;
  font-size: 13px;
  font-weight: 500;
}

.curtain-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  width: 95px;
  height: 95px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.curtain {
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: #000;
  opacity: 0.6;
  display: none;
  position: fixed;
}

/*new css */
.selectearlypaystatus {
  &.mat-form-field-appearance-fill {
    margin: 0px 10px;

    .mat-form-field-label mat-label,
    .mat-select-value-text>span {
      font-size: 12px !important;
      color: #3c3c3c !important;
    }

    .mat-form-field-flex {
      background: #fff;
      border: 1px solid #c4e5ed;
      border-radius: 4px;
      padding-top: 8px;
    }

    .mat-form-field-infix {
      padding: 0 0px 3px !important;
      border-top: 3px solid transparent;
      background: #fff;
    }

    .mat-form-field-underline::before {
      background: none;
    }

    .mat-form-field-ripple {
      height: 0;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(-19%);
    }
  }

  &.mat-form-field-should-float {

    .mat-form-field-flex,
    .mat-form-field-infix {
      // background: #c4e5ed;
    }

    .mat-select-arrow-wrapper {
      transform: translateY(3%);
    }

    .mat-form-field-infix {
      padding: 0 0px 6px;
      border-top: 0 solid transparent;
    }

    .mat-form-field-label {
      display: none !important;
    }
  }
}

.biztype {
  &.mat-form-field-appearance-fill {
    margin: 0px 10px 0px 0px !important;
    // width: 100%;
  }
}

.finan {
  &.mat-form-field-appearance-fill {
    margin: 0px 0px;

    .mat-form-field-flex {
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding-top: 8px;
    }
  }

  &.mat-form-field-should-float {

    .mat-form-field-flex,
    .mat-form-field-infix {
      background: white;
    }
  }
}

.mataccWrapEp {
  width: 230px;

  .mat-expansion-panel {
    box-shadow: none;
    border: #c4e5ed 1px solid;

    .mat-expansion-panel-body {
      background: #fff;
      z-index: 1;
      border-radius: 0 0 4px 4px;
      border: 1px solid #c4e5ed;
      padding-top: 10px;
    }

    .mat-expansion-panel-content {
      position: absolute;
      width: 228px;
      background-color: white;
      z-index: 99999;
    }

    .mat-expansion-panel-header-title {
      font-size: 13px;
    }
  }
}

.md-drppicker {
  width: 550px !important;
}

// .mat-select-panel {
//   position: relative !important;
//   left: 29px !important;
//   width: 140px;
// }

.dash-head-selct .mat-select-arrow {
  border-image-source: url("./assets/images/arrow-down.png") !important;
}

.mat-select-arrow {
  border-left: 15px solid transparent !important;
  border-right: none !important;
  border-top: 15px solid transparent !important;
  border-image-source: url("./assets/images/arrow-drpdown.png") !important;
  border-image-repeat: stretch !important;
  zoom: 1.2;
  position: relative;
  left: 7px;
}

.mat-menu-panel {
  &.selectdatemenu {
    min-width: 230px;

    .mat-radio-group {
      .mat-radio-button {
        display: block;
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}

.mat-sort-header-container {
  .mat-sort-header-arrow.ng-trigger-arrowPosition {
    transform: translateY(-2px) !important;
  }
}

.table_invoice {
  &.suppliertable {
    &.mat-table {
      td.mat-cell {
        &.mat-column-invoiceNumber {
          left: 40px;
          width: 120px;

          .overtext {
            width: 110px;
          }
        }

        &.mat-column-EPStatus {
          left: 160px !important;
          width: 120px;
        }
      }
    }
  }
}

.suplierTable {
  &.mat-table {
    td.mat-cell {
      &.mat-column-supplierName {
        width: 90px;

        .overtext {
          width: 110px;
        }
      }
    }
  }
}

//searclisting loader
.sk-fading-circle {
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
  top: 10px;
}

.sk-fading-circle .sk-circle {
  width: 60%;
  height: 60%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 8%;
  height: 8%;
  background-color: black;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

// .mat-ink-bar {
//   background: #4688f1;
// }

.menuListProfile {
  button a {
    text-decoration: none;
    color: black;
  }

  .swth {
    font-size: 12px;
    color: #555555;
    padding: 0px 10px 5px 16px;
    font-weight: 400;
  }

  .myth {
    font-size: 11px;
    color: #8e8e8e;
    padding: 0px 10px 5px 16px;
  }
}

.menuListProfile.mat-menu-below {
  min-width: 185px;
}

.suplierRadbtn {
  .radiored {
    .mat-radio-container .mat-radio-outer-circle {
      background-color: #e84855;
      border: 1px solid #e84855;
    }
  }

  .radioblue {
    .mat-radio-container .mat-radio-outer-circle {
      background-color: #1566c0;
      border: 1px solid #1566c0;
    }
  }

  .radioorange {
    .mat-radio-container .mat-radio-outer-circle {
      background-color: #fd8237;
      border: 1px solid #fd8237;
    }
  }

  .radiogreen {
    .mat-radio-container .mat-radio-outer-circle {
      background-color: #66bb6b;
      border: 1px solid #66bb6b;
    }
  }

  .radiopurple {
    .mat-radio-container .mat-radio-outer-circle {
      background-color: #ab47bc;
      border: 1px solid #ab47bc;
    }
  }
}

.suplierRadbtn {
  .radiored.mat-radio-checked {
    .mat-radio-container .mat-radio-inner-circle {
      background-color: #e84855;
    }

    .mat-radio-container .mat-radio-outer-circle {
      background-color: white;
    }
  }

  .radioblue.mat-radio-checked {
    .mat-radio-container .mat-radio-inner-circle {
      background-color: #1566c0;
    }

    .mat-radio-container .mat-radio-outer-circle {
      background-color: white;
    }
  }

  .radioorange.mat-radio-checked {
    .mat-radio-container .mat-radio-inner-circle {
      background-color: #fd8237;
    }

    .mat-radio-container .mat-radio-outer-circle {
      background-color: white;
    }
  }

  .radiogreen.mat-radio-checked {
    .mat-radio-container .mat-radio-inner-circle {
      background-color: #66bb6b;
    }

    .mat-radio-container .mat-radio-outer-circle {
      background-color: white;
    }
  }

  .radiopurple.mat-radio-checked {
    .mat-radio-container .mat-radio-inner-circle {
      background-color: #ab47bc;
    }

    .mat-radio-container .mat-radio-outer-circle {
      background-color: white;
    }
  }
}

/*suplier listings dropdown*/
.suplierRadbtn {
  .mat-radio-button {
    vertical-align: middle;
  }

  .mat-radio-inner-circle {
    left: 0px !important;
    top: 0px !important;
    width: 16px !important;
    height: 16px !important;
  }

  .mat-radio-outer-circle {
    width: 16px !important;
    height: 16px !important;
    top: 2px;
    left: 2px;
  }

  .mat-radio-ripple {
    left: calc(50% - 22px);
    top: calc(50% - 22px);
  }

  .mat-radio-checked {
    .mat-radio-outer-circle {
      width: 18px;
      height: 18px;
      top: 0px;
      left: 0px;
    }

    .mat-radio-inner-circle {
      transform: scale(0.66);
    }
  }
}

.matmenu_editsupp {
  &.mat-menu-panel {
    max-width: 210px;
    padding: 0 10px;
    background: none;
    margin-top: -23px;
    margin-left: 15px;
    box-shadow: none;
    max-height: none;
    overflow: visible;
    height: auto;

    .mat-menu-content {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      padding: 0 5px;
      background: #fff;
      border: 1px solid #e1e1e1;

      .editsuppdetails {
        position: relative;
        padding: 5px 0;

        &::before {
          left: -15px;
          border-top: 6px solid transparent;
          border-right: 10px solid #fff;
          border-bottom: 6px solid transparent;
          content: "";
          z-index: 2;
          width: 0;
          height: 0;
          position: absolute;
          top: 10px;
        }

        &::after {
          left: -16px;
          border-top: 6px solid transparent;
          border-right: 10px solid rgba(0, 0, 0, 0.16);
          border-bottom: 6px solid transparent;
          z-index: 1;
          content: "";
          width: 0;
          height: 0;
          top: 10px;
          position: absolute;
        }

        textarea {
          height: 80px;
          font-size: 12px;
          min-width: 100%;
          width: 100%;
          border: 1px solid #dedede;
        }

        .actions {
          text-align: right;

          button {
            height: 25px;
            font-size: 11px;
            line-height: 25px;
            background: #022b5c;
            color: #fff;
          }
        }

        &.commenttext {

          &::before,
          &::after {
            top: 6px;
          }
        }
      }
    }
  }
}

.bokngsht {
  display: flex;
  padding: 10px 0px 10px 34px;

  .mat-tab-group .mat-tab-body {
    .mat-tab-body-content {
      display: flex;
      padding: 40px 0px 0px 30px;
      overflow: hidden;
    }
  }
}

.booking-mat-tab {
  .mat-tab-header {
    .mat-tab-labels {
      .mat-tab-label {
        opacity: 1;

        .mat-tab-label-content {
          font-weight: 500;
          font-size: 13px;
          color: #4688f1;
        }
      }
    }
  }

  .mat-tab-group {
    .mat-tab-body {
      overflow-y: hidden;
    }
  }
}

.color-flag {
  .mat-option-text {
    display: flex;
    align-items: center;

    .dynamiccolor {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      // background: red;
      margin: 0 8px 0 5px;
    }

    .selectRed {
      background-color: #e84855 !important;
    }

    .selectPurple {
      background-color: #ab47bc !important;
    }

    .selectGreen {
      background-color: #66bb6b !important;
    }

    .selectOrange {
      background-color: #fd8237 !important;
    }

    .selectBlue {
      background-color: #1566c0 !important;
    }

    .selectblank {
      border: 1px solid #eee;
    }
  }
}

.snack-class {
  background-color: #00aa27;
  color: white;
}

.matcommentdrop {
  &.mat-menu-panel {
    max-width: 220px;

    .commenttext {
      text-align: right;

      textarea {
        color: #777777;
        font-size: 11px !important;
      }

      button.mat-flat-button {
        width: 91px;
        border-radius: 2px;
        background: #1479fc;
        height: 22px;
        line-height: 22px;
        font-size: 11px;
        font-weight: 400;
      }
    }
  }
}

.sortmenuwrap {
  &.mat-menu-panel {
    button {
      i {
        font-size: 17px;
        margin-right: 10px;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        display: inline-block;
      }

      &:hover {
        i {
          visibility: visible;
        }
      }

      &.seclectdcheckbox {
        i {
          visibility: visible;
        }
      }
    }
  }
}

.table_invoice {
  &.suplierTable {
    .mat-header-row {
      .mat-header-cell {
        &.cdk-column-supplierID {
          width: 250px;
        }

        &.cdk-column-eligibleInvoice,
        &.cdk-column-requestValue {
          width: 195px;
        }

        &.cdk-column-onBoarding {
          width: 110px;
        }

        &.cdk-column-limit {
          width: 160px;
        }

        &.cdk-column-Action {
          width: 185px;
        }

        .mat-checkbox {
          &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background: #022b5c !important;
          }
        }
      }
    }

    .mat-cell {
      .mat-checkbox {
        &.mat-checkbox-checked.mat-accent {
          .mat-checkbox-frame {
            border-color: #022b5c;
          }

          .mat-checkbox-background {
            background: #022b5c !important;
          }
        }
      }

      &.cdk-column-color {
        width: 79px;
      }
    }
  }
}

// .pgheader{
//   .mat-paginator{
//     .mat-paginator-container{ min-height: auto !important;}
//     .mat-paginator-page-size {display: none;}
//     .mat-paginator-range-label {
//         margin: 4px 0px 0 24px;
//     }
//     .mat-icon-button{
//       width: 30px;  height: 30px; line-height: 30px;
//       .mat-paginator-icon{width:21px}
//     }
//   }
// }

.invoiceMenu {
  &.mat-menu-panel {
    .header {
      .rht {
        &>span {
          top: 7px;
          font-size: 17px;
          cursor: pointer;
        }
      }
    }

    .mat-form-field {
      &.dash-selct {
        .mat-form-field-flex {
          padding-top: 7px;
          padding-bottom: 0px;

          .mat-form-field-infix {
            .mat-select {
              .mat-select-arrow {
                top: 5px;
              }
            }
          }
        }
      }

      &.inputBox {
        .mat-form-field-flex {
          padding: 0;
        }

        .mat-form-field-infix {
          padding: 0;

          input {
            height: 28px;
            padding: 0 10px;
          }
        }

        .mat-form-field-label {
          top: 23px;
          left: 10px;
        }
      }
    }
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #022b5c;
}

.ng-tns-c5-8,
.ng-tns-c5-6,
.ng-tns-c5-10,
.ng-tns-c5-12 {
  left: 6px !important;
}

.mat-menu-panel {
  &.invoiceMenu {
    max-height: 500px;
    overflow-y: auto;
  }
}

.sortingmenu {
  button {
    padding-left: 10px;

    i {
      visibility: hidden;
      margin: 4px 8px 4px 0;
      font-size: 15px;
    }

    &:hover {
      i {
        visibility: visible;
      }
    }

    &.sortApplied {
      color: #1479fc;

      i {
        visibility: visible;
      }
    }
  }
}

// .highlighted{
//   color: blue !important;

// }

.table_invoice {
  &.suplierTable {
    tbody {
      .mat-row {
        &.highlighted {
          background-color: #f2f7ff;

          .visibleIcon {
            visibility: visible;

            .mat-icon {
              color: black !important;
            }
          }
        }
      }
    }
  }
}

.ri-check-line:before {
  position: relative;
  top: 2px;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: #022b5c !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #022b5c !important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background: #022b5c !important;
}

.hyperlinktxt {
  cursor: pointer;
}

.hyperlinktxt {
  span:hover {
    color: black;
  }
}

.mat-menu-panel {
  &.invoiceMenu {
    border-radius: 0 0 4px 4px;
  }
}

.mat-select-panel-wrap.ng-tns-c5-1 {
  .mat-option {
    line-height: 36px;
    height: 36px;
  }
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background: #b0b0b0 !important;
}

.stepper-header {
  height: 100%;

  .steps {
    display: block !important;
    width: 40% !important;
    margin: 0px !important;
    position: relative;
    height: 100%;
    background: #f5f9fc 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 9%;
  }
}

.step:after {
  background: none !important;
}

.step.completed+.step:after {
  background: none !important;
}

.stepper-body {
  position: absolute !important;
  left: 27%;
  top: 1px;
  margin: 0px !important;
  padding: 0px !important;
}

.stepper-header .steps .step {
  margin-bottom: 20px;
  text-align: inherit !important;
  cursor: pointer;
  //  pointer-events: none;
}

.stepper-header .steps .step .step-title {
  display: contents;
  font-size: 13px;
}

.step {
  width: 45% !important;
}

.stepper-header .steps .step .step-bullet {
  width: 20px !important;
  height: 20px !important;
  font-size: 12px !important;
  margin-right: 7px !important;
}

.stepper {
  overflow: inherit !important;
  height: 100%;
}

.stepper-header .steps .step.active .step-title {
  color: #1479fc !important;
}

.accoutTypeRadio {
  .mat-radio-container .mat-radio-outer-circle {
    border: 1px solid #3640bb !important;
  }

  .mat-radio-checked .mat-radio-container .mat-radio-inner-circle {
    background: #3640bb !important;
  }
}

.buyerList {
  width: 80px;

  .mat-form-field-underline {
    height: 0px !important;
  }

  .mat-select-arrow {
    opacity: 0;
  }

  .mat-form-field-suffix {
    top: 5px;
  }

  .mat-form-field-wrapper {
    color: #022a5d;
  }
}

.details_section {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #1479fc;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
  }
}

.icon_details {
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.mat-stepper-horizontal.payout-stepper {
  background-color: #f5f9fc;

  .mat-horizontal-stepper-header-container {
    width: 28%;
    margin: auto;
  }

  .mat-horizontal-stepper-header .mat-step-label {
    position: absolute;
    margin-top: -10px;
    left: 56%;
  }
}

.below-tabs .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.escrowMatStepper {
  .mat-horizontal-stepper-header-container {
    .mat-horizontal-stepper-header {
      .mat-step-icon-state-done {
        background-color: #4CB21E !important;
      }

      .mat-step-icon-state-edit {
        background: #000000 !important;
      }

      .mat-step-icon-state-number {
        background: #a8a8a8;
      }
    }
  }

  .mat-step-icon {
    height: 18px;
    width: 18px;
    border-radius: 3px;

    .mat-icon {
      font-size: 14px;
      height: 14px;
      width: 15px;
    }
  }

  .mat-horizontal-stepper-header-container {
    width: 42%;
    margin: auto;
    position: absolute;
    top: -18px;
    left: 30%;
  }

  .mat-step-icon-content {
    font-size: 12px;
    font-weight: 500;
  }

  .mat-step-label {
    font-size: 13px;
    font-weight: 500;

    &:not(.mat-step-label-selected) {
      color: rgba(0, 0, 0, .54) !important;
    }
  }

  .mat-horizontal-content-container {
    background-color: #f5f9fc;
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover {
    background: none !important;
  }
}

.mat-step-header {
  pointer-events: none !important;
}

/* css changes start */
.d-flex {
  display: flex !important;
}

.flex-direction-column,
.flex-column {
  flex-direction: column !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

// .mb-10 {
//   margin-bottom: 10px !important;
// }

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.w-24 {
  width: 24%;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}
.w-55 {
  width: 55%;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.33%;
}

.w-19 {
  width: 19.2%;
  margin-right: 1%;
  &:nth-child(5n){
    margin-right: 0;
  }
}

.ml-20 {
  margin-left: 20px !important;
}

.fixedTablecolumn-3 {
  thead {
    th {
      &:first-child {
        left: 0px !important;
        width: 220px;
        max-width: 220px;
        min-width: 220px;
      }

      &:nth-child(2) {
        left: 220px !important;
        width: 130px;
        min-width: 130px;
        max-width: 130px;
      }

      &:nth-child(3) {
        border-right: 1px solid #ccc;
        left: 350px !important;
        width: 60px;
        min-width: 60px;
        max-width: 60px;

      }
    }
  }

  tbody {
    tr {
      td {
        span.status-dot {
          font-size: 16px !important;
          display: inline-flex;
          align-items: center;
          margin-right: 5px;
        }

        &:first-child {
          left: 0px !important;
          width: 220px;
          max-width: 220px;
          min-width: 220px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:nth-child(2) {
          left: 220px !important;
          width: 130px;
          min-width: 130px;
          max-width: 130px;
        }

        &:nth-child(3) {
          border-right: 1px solid #ccc;
          left: 350px !important;
          width: 60px;
          min-width: 60px;
          max-width: 60px;

        }
      }
    }
  }
}

.blue-anchor {
  text-decoration: unset !important;
  font-size: 13px;
  line-height: 13px;
  color: #0062FF;
}

.left-p {
  padding: 20px 30px !important;
}

.calcArea {
  margin-bottom: 35px !important;

  label {
    font-size: 13px !important;
  }

  span {
    font-size: 17px !important;
    font-weight: 500 !important;
  }
}

.right-top {
  h3 {
    font-size: 14px !important;
    line-height: 16px;
    font-weight: 500 !important;
  }

  label {
    font-weight: 500;
  }
}

.dragArea {
  span {
    font-size: 13px;
    line-height: 14px;
    letter-spacing: 0.66px;
  }
}

.right-sec {
  height: inherit !important;

  .dragArea {
    height: calc(100% - 36px) !important;
  }
}

.tab-style-1 {
  .mat-tab-label {
    opacity: 1;
    min-width: auto;
    padding: 0px;
    justify-content: flex-start;
    margin-right: 40px;

    &.mat-tab-label-active .mat-tab-label-content {
      color: #1479FC;
    }
  }

  .warning-icon {
    color: #DDA225;
    margin-right: 8px;
  }

  .checked-icon {
    color: #4CB200;
    margin-right: 8px;
  }

  .timer-icon {
    color: #7B7B7B;
    margin-right: 8px;
  }

  .mat-tab-label-content {
    font-size: 14px;
    line-height: 38px;
  }

  // .mat-ink-bar {
  //   height: 3px;
  // }
}

.save-btn {
  border-radius: unset !important;
  border-left: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;

  .mat-button-wrapper {
    i {
      font-size: 20px;
    }
  }
}

.mat-horizontal-stepper-header-container {
  left: 24% !important;
}

.tableCell-overflow {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html .input-btn {
  border: 1px solid #c4e5ed !important;

  input {
    border: none !important;
  }

  button {
    position: relative !important;
  }
}

/* css changes end */

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

/* dashboard components start*/



.top-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .chart-filter {
    background-color: white;
    border: 1px solid #DFE5F8;
    border-radius: 6px;
    padding: 11px 10px;
    line-height: 14px;

    .mat-button-wrapper {
      font-size: 12px;

      i {
        font-size: 19px;
      }
    }

    &:not(:first-child) {
      margin-left: 16px;
    }
  }
}

.chartsMoreOption-btn {
  min-width: 30px !important;
  padding: 0px 8px !important;
  font-size: 21px;
}

.moreOptionMenu {
  border: 1px solid #DFE5F8;
  border-radius: 6px !important;
  font-size: 12px;

  .mat-menu-content {
    padding: 1px 0px !important;
  }

  .mat-menu-item {

    display: flex;
    align-items: center;
  }

  i {
    font-size: 20px;
    margin-right: 12px;
  }
}

.chart-filter-options {
  min-width: 204px !important;
  max-height: 376px !important;
  height: 376px;

  mat-form-field {
    margin: 7px 10px 0px 10px;
    font-size: 12px;

    .mat-form-field-wrapper {
      padding-bottom: 0px;
      margin: 0px;
    }
  }

  .mat-menu-content {
    padding: 10px 0px;

    .checkbox-list {
      list-style: none;
      padding-left: 12px;
      height: 256px !important;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
      }

      /* background of the scrollbar except button or resizer */
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-track:hover {
        background-color: #f4f4f4;
      }

      /* scrollbar itself */
      &::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 5px solid #fff;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        border: 4px solid #f4f4f4;
      }

      /* set button(top and bottom of the scrollbar) */
      &::-webkit-scrollbar-button {
        display: none;
      }


      li {
        &:not(:last-of-type) {
          margin-bottom: 15px;
        }

        .mat-checkbox {
          font-size: 14px;
          line-height: 14px;

          .mat-checkbox-background {
            background: #fff !important;
          }

          .mat-checkbox-ripple {
            display: none;
          }

          &.mat-checkbox-checked {
            color: #0052CC !important;
          }

          &.mat-checkbox-checked .mat-checkbox-background {
            background-color: #0052CC !important;
            border: unset;
          }
        }
      }
    }

    .cta-section {
      padding-left: 12px;

      .cta-btn {
        margin-left: 20px;
      }
    }
  }

}

mat-radio-button {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;

  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: #0052CC !important;
  }

  .mat-radio-container {
    width: 18px;
    height: 18px;

    .mat-radio-outer-circle {
      height: inherit;
      width: inherit;
      border-width: 5px;
    }

    .mat-radio-inner-circle,
    .mat-radio-ripple {
      opacity: 0;
    }

  }

}

.custom-row {
  display: flex;
  margin: 0 -15px;
  margin-bottom: 30px;
}

.col-custom {
  padding: 0px 15px;
}

.h-62 {
  height: 62% !important;
}

.h-32 {
  height: 32% !important;
}

.h-100 {
  height: 100% !important;
}

.h-70 {
  height: 70% !important;
}

.mt-5p {
  margin-top: 5%;
}

.mt-10p {
  margin-top: 10%;
}

.ml-10 {
  margin-left: 10px !important;
}

.mb-5p {
  margin-bottom: 5%;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-30 {
  width: 30%;
}

.w-100 {
  width: 100% !important;
}

.w-35 {
  width: 35%;
}

.w-25 {
  width: 25%;
}

.w-32 {
  width: 31.5%;
}

.w-36 {
  width: 36.2%;
}

.w-62 {
  width: 62.5%;
}


.w-10 {
  width: 10%;
}

.w-45 {
  width: 45%;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.flex-column {
  flex-direction: column !important;
}

.theme-blue-bg {
  background-color: #0074FF !important;
}

.processngTime,
.numbersToday {
  .contnt {
    background-color: #FDF9F3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px 13px;

    label {
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 10px;
    }

    span {
      font-size: 20px;
      line-height: 1;
      font-weight: 500;
    }
  }

  .graph {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin: 0px 20px;

    .custom-row {
      margin-left: 0px;
      margin-right: 0px;
      display: flex;

      .col-custom:last-child {
        align-items: center;
        position: relative;

        &:before {
          content: '';
          height: 70%;
          width: 2px;
          position: absolute;
          left: 0px;
          top: 38%;
          border-left: 1px solid #E8E8E8;
        }
      }
    }

    label {
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      margin-bottom: 10px;
    }

    span {
      font-size: 1rem;
      line-height: 1;
      font-weight: 500;
    }

    .time-outline {
      margin-bottom: 23px;
      width: 56px;
      height: 7px;
      border-radius: 5px;

      &.bg-1 {
        background-color: #B2A285;
      }

      &.bg-2 {
        background-color: #D1BC97;
      }
    }
  }
}

.numbersTodaySection {

  h2,
  .btn-more,
  label,
  span {
    color: #fff !important;
  }

  .graph {
    position: absolute;
    margin: 0px;
    left: 11px;

    span:last-child {
      margin-left: 30px;
    }

    .custom-row {
      .col-custom {
        padding: 0px 5px !important;
      }

      .col-custom:last-child {
        &:before {
          display: none;
        }
      }
    }
  }

  .time-outline {
    background-color: #fff !important;

  }
}

.chars-tab {
  margin-top: 5px;

  mat-tab-header {
    border-bottom: 2px solid #E7E8F2;
    border-top: 2px solid #E7E8F2;

    .mat-tab-label {
      min-width: 33.33%;
      justify-content: center;
      opacity: 1;
      height: 30px;

      .mat-tab-label-content {
        .chart-label {
          font-size: 12px;
          line-height: 13px;
          color: #000;
          font-weight: 400;
        }

        .chart-amount {
          font-size: 12px;
          line-height: 13px;
          margin-left: 15px;
          font-weight: 600;

          &.text-green {
            color: #15a21a;
          }

          &.text-red {
            color: #d92c2d;
          }
        }
      }

      &.mat-tab-label-active {
        background-color: #E4F0FE;
      }

      &:not(:last-of-type) {
        border-right: 2px solid rgba(0, 0, 0, .12);
      }
    }

  }

  .mat-tab-body-wrapper {
    margin-top: 15px;
  }

  // mat-ink-bar {
  //   display: none;
  // }
}

.view-more {
  text-decoration: unset;
  color: #0074FF;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.cta-btn {
  @extend .view-more;
  color: #0052CC;
}

.cta-btn-grey {
  @extend .view-more;
  color: #000;

}

.filter-card {
  .processingAmt {
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: center;
  }
}

/* dashboard components end*/
.invoice thead tr th.mat-header-cell {
  padding: 0px 9px !important;

}

.invoice tbody tr td.mat-cell {
  padding: 0px 9px !important;
}

.line-broken-tooltip {
  white-space: pre-line;
  margin: 3px 0px;
  padding: 4px;
  line-height: 1.5;

}

.d-none {
  display: none !important;
}

// .mat-table-sticky:nth-child(2) {
//   border-right: 1px solid #e0e0e0;
// }

.hv-100 {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.theme-lighblue-bg {
  background-color: #f5f9fc;
  display: block;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.al-center{
  align-items: center;
}

.js-between{
  justify-content: space-between;
}

.js-end{
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.w-32{
  width: 32%;
  margin-right: 2%;

  &:nth-child(3n){
      margin-right: 0;
  }
}

.w-22{
  width: 22%;
  margin-right: 2%;
}

.w-15{
  width: 15%;
  margin-right: 2%;
}


.w-48{
  width: 48%;
}

.w-49{
  width: 49%;
  margin-right: 2%;
  &:nth-child(2n){
    margin-right: 0;
  }
}

.mb-15{
  margin-bottom: 15px;
}

.white-pre{
  white-space: pre;
}


.chck {
  .mat-checkbox-inner-container {
    width: 12px;
    height: 12px;
  }

  .mat-checkbox-label {
    font-size: 13px;
    position: relative;
    top: 2px;
  }
}

.leadComment {
  position: fixed !important;
  right: 0px;
  top: 0px;
  width: 600px !important;
  max-width: 100% !important;
}

.eximSetting {
  position: fixed !important;
  right: 0px;
  top: 0;
}

.align-self-start {
  align-self: flex-start;
}

.h-100 {
  height: 100% !important;
}

.laneAccordionConfig {
  .mat-expansion-panel {
    margin-bottom: 10px !important;
    box-shadow: none;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.icon-btn-sm {
  padding: 5px 10px;
  font-weight: 500;
  color: rgb(0 0 0 / 87%);
  font-size: 15px;

}


// for thin border
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
}

.txt-red {
  color: #FF0000 !important
}

.w-100 {
  width: 100% !important;
}

.custom-col-6 {
  padding: 0px 15px;
  width: 50%;
  flex: 0 0 50%;
}

.bottomSheet-panel-class {
  width: 100vw !important;
  padding: 42px !important;
  max-width: 100vw !important;
}


.input-ui {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, .12);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #6C6C6C3C;
  font-size: 14px;
  height: 40px;
}


.search-input {
  padding: 12px 16px;
  width: 20vw;
  font-weight: normal;
}


.flex-between-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .pagination-wrapper {
    // text-align: r;
  }
}


.status-filter-th {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg,
  img {
    margin-left: 8px;
    height: 14px;
  }
}

.status-filter-th {
  .md-drppicker {
    top: 3.7rem !important;
    left: -60% !important;

    .btn {
      background-color: #1479FC !important;
      padding: 5px 12px !important;
      line-height: 20px !important;
      font-size: 12px !important;
      border-radius: 0.25rem;
    }

    td.available:not(.off) {
      color: black;
    }
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.custom-col-6 {
  padding: 0px 15px;
  width: 50%;
  flex: 0 0 50%;
}

// presales dialog
.move-ready-style {
  position: absolute !important;
  right: 0 !important;
}

.mat-paginator-container {
  min-height: 44px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

mat-paginator.custom-pagintor {
  button.mat-icon-button {
    height: 2.25em;
    width: 2.5em;
    background-color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-right-width: 0;
    border-radius: 0;

    .mat-paginator-container * {
      color: #363636;
    }

    &.mat-paginator-navigation-first {
      border-radius: 4px 0 0 4px;
      border: 1px solid #ccc;
      border-right-width: 0;
    }

    &.mat-paginator-navigation-last {
      border-radius: 0 4px 4px 0;
      border: 1px solid #ccc;

    }

    .mat-paginator-icon {
      height: 18px;
      fill: #363636a6;
    }
  }
}

.custom-table-wrapper {
  padding: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
}

.p-3 {
  padding: 16px;
}

.pt-0 {
  padding-top: 0;
}

.mat-paginator {
  background-color: transparent;
}

.nd-btn {
  border: 1px solid #ccc !important;
  line-height: normal !important;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: #fff !important;
  padding: .25em 1em !important;
  font-size: 400 !important;

  * {
    font-size: 400 !important;
  }
}

.h-32 {
  height: 2.5em !important;
  min-height: 2.5em !important;
}

.mat-paginator-range-label {
  margin: 0 1.5em;
}

.sb_table {
  border: 1px solid #cccccc;
}

.fg-1 {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.disable-element {
  opacity: .25;
  cursor: not-allowed !important;

  * {
    pointer-events: none;
  }
}

.new-tooltip {
  background-color: #0a0a0a;
  color: #fff;
  font-size: .8rem !important;
}

.mt-2rem {
  margin-top: 2rem;
}

.confirm-dialog .mat-dialog-container {
  padding: 40px !important;
}


.selectDropdwnList {
  .dropdown-ico {
    position: absolute;
    top: 10px;
    right: 2px;
  }
}

.tagged-user,
.date-text {
  color: blue !important;
}
.pendingAppr {
  color: #f0ecec;
  border-radius: 14px;
  padding: 4px 10px;
  background: #ffc107 0% 0% no-repeat padding-box !important;
  opacity: 1;
}

.approved {
  color: #f0ecec;
  border-radius: 14px;
  padding: 4px 10px;
  background: #8de3ff 0% 0% no-repeat padding-box !important;
  opacity: 1;
}
.partiallySettled {
  color: #f0ecec;
  border-radius: 14px;
  padding: 4px 10px;
  background: #6cd484 0% 0% no-repeat padding-box;
  opacity: 1;
}

.verified {
  color: #f0ecec;
  border-radius: 14px;
  padding: 4px 10px;
  background: #28a745 0% 0% no-repeat padding-box;
  opacity: 1;
}
.alignDiv-item{
  display: flex !important;
  justify-content: space-between !important;
}
.approved-custom-autocomplete {
  min-width: 136px !important;
  margin-left: -13px !important;
}
